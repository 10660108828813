// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'styles/global'
import 'styles/frontend'


import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import "channels"

import Popper from 'popper.js/dist/umd/popper.js';
window.Popper = Popper;

import 'bootstrap'

Rails.start()
Turbolinks.start()
ActiveStorage.start()


document.addEventListener("turbolinks:load", () => {
    $('#viewProfile').on("click", (e) => {
        $(e.target).toggle()
        $("#updateProfile").toggle()
        $('#readonly-profile').toggle()
        $("#edit-profile").toggle()
    })

    $('#cancel-update-button').on("click", (e) => {
        $('#viewProfile').toggle()
        $("#updateProfile").toggle()
        $('#readonly-profile').toggle()
        $("#edit-profile").toggle()
    })


  $('#viewContact').on("click", (e) => {
    $(e.target).toggle()
    $("#updateContact").toggle()
    $('#readonly-contact').toggle()
    $("#edit-contact").toggle()
  })

  $('.viewPractice').on("click", (e) => {
    $(e.target).toggle()
    const id = $(e.target).attr('id').split("view_")[1]
    $(`#updatePractice${id}`).toggle()
    $(`#readonly-practice${id}`).toggle()
    $(`#edit-practice${id}`).toggle()
  })

  $('.cancel-practice-update-button').on("click", (e) => {
    const id = $(e.target).attr('id').split("cancel_")[1]
    console.log(id)
    $(`#view_${id}`).toggle()
    $(`#updatePractice${id}`).toggle()
    $(`#readonly-practice${id}`).toggle()
    $(`#edit-practice${id}`).toggle()
  })

  $('#cancel-contact-update-button').on("click", (e) => {
    $('#viewContact').toggle()
    $("#updateContact").toggle()
    $('#readonly-contact').toggle()
    $("#edit-contact").toggle()
  })


    $('[data-toggle="tooltip"]').tooltip()

      // This is to make the tabs display based on the url for pages using tabs
      let url = location.href.replace(/\/$/, "");
      if (location.hash) {
        const hash = url.split("#");
        $('a[href="#'+hash[1]+'"]').tab("show");
      }else{
        //if there is no # in the url, show the first tab
        $('a[href^="#"]').first().tab("show");
      }
      
      $('a[data-toggle="tab"]').on("click", function() {
        let newUrl;
        const hash = $(this).attr("href");
        if(hash == "#home") {
          newUrl = url.split("#")[0];
        } else {
          newUrl = url.split("#")[0] + hash;
        }
        history.replaceState(null, null, newUrl);
      });
      $("#reset-button").on("click", (e) => {
        $("#signinResource").toggle()
        $("#resetResource").toggle()
        $("#migration-warning").toggle()
      })
})